import {connect} from "react-redux";
import NewsOverview from "./NewsOverview";
import {toogleNewsFilter} from '../actions';

const find = (filter, categories) => {
    let retVal=false;
    categories.forEach(({name}) => {
        if(filter.find(f => f===name)){
            retVal = true;
        }
    });
    return retVal;
};

const filter = (filterOpen, filter, posts, preview) => {
    if(preview){
        let myFind = (filterOpen && filter && filter.length>0)? find: () => {return true};

        let edges = [];
        let index = posts.edges.findIndex(({node}) => node.id === preview);
        for(let i=index-1; i>=0; i--){
            if(myFind(filter, posts.edges[i].node.categories))
                edges.push(posts.edges[i]);
            if(edges.length>1)
                break;
        }
        edges = edges.reverse();
        for(let i=index+1; i<posts.edges.length; i++){
            if(myFind(filter, posts.edges[i].node.categories))
                edges.push(posts.edges[i]);
            if(edges.length>2)
                break;
        }
        return {...posts, edges};
    }
    if(filter && filter.length>0){
        let edges =  posts.edges.filter(({node}) => filterOpen? find(filter, node.categories): true);
        return {...posts, edges};
    }
    return posts
};

const mapStateToProps = (state, ownProps) => ({
    data: filter(state.filterOpen, state.filter, ownProps.data, ownProps.preview),
    filterOpen: state.filterOpen
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    toogleFilter: () => dispatch(toogleNewsFilter())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NewsOverview);
