import React from "react";
//import { navigateTo } from 'gatsby-link'

import {graphql} from "gatsby";
import Img from 'gatsby-image'
import injectSheet from 'react-jss';

//import { createLocation } from "history";

import Layout from '../containers/layout';
import SubPage from '../containers/subpage';

import ReactHtmlParser from 'react-html-parser'
import ReactPlayer from 'react-player';
import Video from "../components/Video";


import {filter} from '../containers/FilteredNewsOverview';
import FilteredNewsOverview from "../containers/FilteredNewsOverview";

const columnGap = 20;

const styles = theme => ({
    blogHeader: {
        flex: '1 1 auto',
    },
    pagination: {
        flex: '0 0 auto',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        }
    },
    image: {
        '& span':{
            display: 'block',
        },
    },
    [theme.breakpoints.up('md')]: {
        content: {
            position: 'relative',
            transition: 'left 250ms ease',
        },
        contentWrapper: {
//            columnFill: 'auto',
//            columnWidth: 300,
//            columnGap: columnGap,
//            maxHeight: 'calc(100vh - 13em)',
//            overflow: 'hidden',
        },
    },
});


class BlogPost extends React.Component {
    state = {
        pagination: false,
        disableColumn: false,
        pages: 0,
        currentPage: 0,
        showControl: true,
        showVideo: false
    };

    constructor(props){

        super(props);

        this.handleResize = this.handleResize.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleForward = this.handleForward.bind(this);

        if(typeof window !== 'undefined' && window.addEventListener){
        }
    }

    componentDidMount() {
//        window.addEventListener('resize', this.handleResize);
//        this.handleResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        if(!this.ref || !this.ref.getBoundingClientRect)
            return;
        const contentRect = this.ref.getBoundingClientRect();
        const parentRect = this.ref.parentNode.getBoundingClientRect();
        const disableColumn = contentRect.height < parentRect.height;

        if(disableColumn){
            this.setState({
                disableColumn,
                pagination: false,
            });
            return;
        }

        const pagination = contentRect.width > parentRect.width;
        if(pagination) {
            const pages = Math.round(contentRect.width/parentRect.width);
            this.setState({
                pagination,
                disableColumn,
                pages,
                currentPage: 0,
                viewport: parentRect.width,
            });
        } else {
            this.setState({
                pagination,
                disableColumn
            });
        }
    }

    handleForward(event) {
        event.preventDefault();

        if(this.state.currentPage < this.state.pages - 1){
            this.setState({
                currentPage: this.state.currentPage + 1
            });
        }
    }

    handleBack(event) {
        event.preventDefault();

        if(this.state.currentPage > 0){
            this.setState({
                currentPage: this.state.currentPage - 1
            });
        }
    }

    render() {
        const {data, classes, location, history} = this.props;
        const post = data.post;
        let localFile = post.featured_media.localFile;
        let gatsbyImg;
        let content = post.content;

        let transform = ({type, name, attribs}, index) => {
            if (type === 'tag' && name === 'img') {
                let edges = data.allWordpressWpMedia.edges;
                for (let i = 0; i < edges.length; i++) {
                    let sourceUrl = edges[i].node.source_url;
                    sourceUrl = sourceUrl.substring(0, sourceUrl.lastIndexOf('.'));
                    if (attribs.src.indexOf(sourceUrl) === 0) {
                        let edge = edges[i];
                        const localFile = edge.node.localFile;
                        let sizes = localFile.childImageSharp.sizes;
                        return (
                            <Img
                                key={localFile.name}
                                Tag="span"
                                src={sizes.src}
                                sizes={sizes}
                                outerWrapperClassName={classes.image}
                            />
                        );
                    }
                }
            }
        };
        content = new ReactHtmlParser(content, {transform});

        if (localFile) {
            let style = post.custom_fields.youtube? {position: 'absolute', top:0, bottom:0, left:0, right:0}: null;
            let sizes = localFile.childImageSharp.sizes;
            gatsbyImg = (
                <Img
                    style={style}
                    src={sizes.src}
                    sizes={sizes}
                />
            );
        }

        let pagination = null;
        let styles = null;
        if(this.state.pagination === true){
            styles = {
                left: -this.state.currentPage * (this.state.viewport + columnGap),
            };
            pagination = (
                <div className={classes.pagination}>
                    <a href="#" onClick={this.handleBack} className="touchable">&lt;</a>
                    <span>{this.state.currentPage + 1} / {this.state.pages}</span>
                    <a href="#" onClick={this.handleForward} className="touchable">&gt;</a>
                </div>
            );
        }

        let contentWrapperClasses = !this.state.disableColumn? classes.contentWrapper + ' container': 'container';

        if(post.custom_fields.youtube){
            gatsbyImg = (
                <div style={{position: 'relative', minHeight: 200}}>
                    {gatsbyImg}
                    <div style={{position: 'absolute', top:0, bottom:0, left:0, right:0, zIndex: 100}}>
                        <Video
                            url={post.custom_fields.youtube[0]}
                            width='calc((100vh - 20rem) * 16 / 9)'
                            showControl={this.state.showControl}
                            showVideo={this.state.showVideo}
                            onPlayClicked={() => this.setState({showControl: false, showVideo: true})}
                            onEnded={() => this.setState({showControl: true, showVideo: false})}
                            onPlay={() => this.setState({showControl: false})}
                            onPause={() => this.setState({showControl: true})}
                        />
                    </div>
                </div>
            );
        }

        const filter = (filter, posts) => {
            if(filter && filter.length>0){
                let edges =  posts.edges.filter(({node}) => {
                    let retVal=false;
                    node.categories.forEach(({name}) => {
                        if(filter.find(f => f===name)){
                            retVal = true;
                        }
                    });
                    return retVal;
                });
                return {...posts, edges};
            }
            return posts
        };

        return (
            <Layout pageTitle={"News"}>
                <SubPage
                    back={"/news/"}
                    image={gatsbyImg}
                    header={(
                        <>
                            <h2 className={classes.blogHeader} dangerouslySetInnerHTML={{__html: post.title}}/>
                            {pagination}
                        </>
                    )}
                >
                    <div className={contentWrapperClasses}>
                        <div className={classes.content} style={styles} ref={ref => this.ref=ref}>
                            {content}
                        </div>
                    </div>
                    <FilteredNewsOverview
                        data={data.posts}
                        className={'container'}
                        preview={data.post.id}
                    />
                </SubPage>
            </Layout>
        );
    }
}

export default injectSheet(styles)(BlogPost);

export const query = graphql`
    query BlogPostQuery($wp_id: Int!) {
        post: wordpressPost( wordpress_id: {eq: $wp_id} ) {
            id
            slug
            title
            content
            date
            modified
            template
            featured_media {
                localFile {
                    childImageSharp {
                        sizes(maxWidth: 2048) {
                            ...GatsbyImageSharpSizes_tracedSVG
                        }
                    }
                }
            }
            custom_fields{
                youtube: wpcf_youtube
            }
        }
        allWordpressWpMedia (filter: {post: {eq: $wp_id}}) {
            edges {
                node {
                    id
                    title
                    source_url
                    localFile {
                        name
                        childImageSharp {
                            sizes(maxWidth: 2048) {
                                ...GatsbyImageSharpSizes_tracedSVG
                            }
                        }
                    }
                }
            }
        }
        posts: allWordpressPost(
            filter: { status: {eq: "publish"} }
        ) {
            edges {
                node {
                    id
                    slug
                    title
                    excerpt
                    date
                    modified
                    template
                    featured_media {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 2048, grayscale: true) {
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    sizes
                                }
                            }
                        }
                    }
                    categories{
                        name
                    }
                }
            }
        }
    }
`;